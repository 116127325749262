<template>
    <div class="page-device-terminals">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button type="danger" @click="deleteTerminals()" size="medium">删除终端</el-button>
                </div>
                <div class="fr">
                    <el-select class="mrgr5 mrgb5" v-model="filter.status" placeholder="状态" clearable @change="getDataList()" size="medium">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="搜索订单号/设备号" clearable v-model="filter.keywords" size="medium"></el-input>
                    <el-button class="mrgb5" type="primary" @click="_search()" size="medium"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-alert class="mrgb10 colff0" type="error">
                离线数:{{ tableItems.offlineCount }}
                ,以弹出数:{{ tableItems.ejectedCount }}
                ,待租数:{{ tableItems.waitingForHireCount }}
                ,租赁中数:{{ tableItems.leasingCount }}
            </el-alert>
            <el-table :data="tableData" border fit highlight-current-row row-key="id"
                @selection-change="handleSelectionChange" @sort-change="sortChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="id" label="ID" width="200">
                    <template slot-scope="scope">
                    <div>{{ scope.row.id }}</div>
                    <div>设备:{{ scope.row.deviceId || '--' }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="slotNo" label="槽位" width="100"></el-table-column>
                <el-table-column prop="electricity" label="电量" width="100" sortable="custom">
                    <template slot-scope="scope">{{ scope.row.electricity }}%</template>
                </el-table-column>
                <el-table-column prop="status" label="状态" width="100" sortable="custom">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status === 0">{{ scope.row.statusDesc }}</el-tag>
                        <el-tag type="info" v-else-if="scope.row.status === -1">{{ scope.row.statusDesc }}</el-tag>
                        <el-tag type="primary" v-else-if="scope.row.status === 1">{{ scope.row.statusDesc }}</el-tag>
                        <el-tag type="danger" v-else>{{ scope.row.statusDesc }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="lastBorrowTime" label="最近租借" width="110" sortable="custom">
                    <template slot-scope="scope">
                        <template v-if="scope.row.lastBorrowTime">
                            {{ getFormatDate(scope.row.lastBorrowTime, null, 'YYYY/MM/DD') }}
                            <br />
                            {{ getFormatDate(scope.row.lastBorrowTime, null, 'HH:mm:ss')}}
                        </template>
                        <template v-else>--</template>
                    </template>
                </el-table-column>
                <el-table-column prop="lastReturnTime" label="最近归还" width="110" sortable="custom">
                    <template slot-scope="scope">
                        <template v-if="scope.row.lastReturnTime">
                            {{ getFormatDate(scope.row.lastReturnTime, null, 'YYYY/MM/DD') }}
                            <br />
                            {{ getFormatDate(scope.row.lastReturnTime, null, 'HH:mm:ss') }}
                        </template>
                        <template v-else>--</template>
                    </template>
                </el-table-column>
                <el-table-column prop="lastLoginTime" label="最近登录" width="110" sortable="custom">
                    <template slot-scope="scope">
                        <template v-if="scope.row.lastLoginTime">
                            {{ getFormatDate(scope.row.lastLoginTime, null, 'YYYY/MM/DD') }}
                            <br />
                            {{ getFormatDate(scope.row.lastLoginTime, null, 'HH:mm:ss') }}
                        </template>
                        <template v-else>--</template>
                    </template>
                </el-table-column>
                <el-table-column prop="lastOfflineTime" label="最近离线" width="110" sortable="custom">
                    <template slot-scope="scope">
                        <template v-if="scope.row.lastOfflineTime">
                            {{ getFormatDate(scope.row.lastOfflineTime, null, 'YYYY/MM/DD') }}
                            <br />
                            {{ getFormatDate(scope.row.lastOfflineTime, null, 'HH:mm:ss') }}
                        </template>
                        <template v-else>--</template>
                    </template>
                </el-table-column>
                <el-table-column prop="lastEjectTime" label="最近强弹" width="110" sortable="custom">
                    <template slot-scope="scope">
                        <template v-if="scope.row.lastEjectTime">
                            {{ getFormatDate(scope.row.lastEjectTime, null, 'YYYY/MM/DD') }}
                            <br />
                            {{ getFormatDate(scope.row.lastEjectTime, null, 'HH:mm:ss') }}
                        </template>
                        <template v-else>--</template>
                    </template>
                </el-table-column>
                <el-table-column prop="lastEjectTime" label="上次所在设备" width="170">
                    <template slot-scope="scope">{{ scope.row.lastDeviceId ||'--' }}</template>
                </el-table-column>
                <el-table-column prop="lastEjectTime" label="最近订单" width="180">
                    <template slot-scope="scope">{{ scope.row.lastOrderId || '--' }}</template>
                </el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="takeSlotNo(scope.row.slotNo, scope.row.deviceId)" :disabled="scope.row.status !== 0 && scope.row.status !== -3">弹出</el-dropdown-item>
                                <el-dropdown-item @click.native="skipToRoute(`/device/terminallogs?terminalId=${scope.row.id}`)">查看日志</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funDevice from "@/api/device/index"
export default {
    name: "pageDeviceTerminals",
    mixins: [enumConfigs],
    data() {
        return {
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                status: null,
                keywords: "",
                sortDirection: 0,
                sortField: null
            },
            multipleSelection: [],
            memberItems: []
        }
    },
    computed: {
        statusOptions() {
            return  this.arrXDeviceTerminalStatuse
        }
    },
    activated() {
        if (this.$route.query.id) {
            this.filter.keywords = this.$route.query.id
        } else {
            this.filter.keywords = ""
        }
        this.getDataList()
    },
    created() {
        if (this.$route.query.id) {
            this.filter.keywords = this.$route.query.id
        }
        this.getDataList()
    },
    methods: {
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        // 在列表选择设备 否
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val
        },
        // 删除终端
        async deleteTerminals() {
            if (this.multipleSelection.length == 0) {
                this.errorMsg("请选择终端")
                return false
            } else {
                this.confirm(`请再次确认是否删除终端`).then(() => {
                    var deviceIds = []
                    this.multipleSelection.forEach(item => {
                        deviceIds.push(item.id)
                    })
                    funDevice.TerminalDelete({ ids: deviceIds }).then(res => {
                        this.successMsg("删除成功")
                        this.getDataList()
                    })
                })
            }
        },
        async getDataList() {
            window.$common.fullLoading()
            funDevice.GetTerminalList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async takeSlotNo(slotNo, id) {
            this.confirm(`确认弹出${ slotNo }卡位吗?`).then(() => {
                window.$common.fullLoading()
                funDevice.Eject({
                    slot: slotNo,
                    id: id
                }).then(() => {
                    window.$common.closeFullLoading()
                    this.successMsg("弹出成功")
                    this.getDataList()
                })
            })
        },
        async sortChange(e) {
            if (e.order) {
                this.filter.sortField = e.prop
                if (e.order == "ascending") {
                    this.filter.sortDirection = 0
                } else {
                    this.filter.sortDirection = 1
                }
            } else {
                this.filter.sortDirection = null
                this.filter.sortField = null
            }
            this._search()
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        }
    }
}
</script>

<style lang="scss" scoped>
.page-device-terminals {}
</style>
